<template>
  <div class="handover-card card">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.handover-card.card {
  display: flex;
  padding: 16px;
  flex-direction: column;
}
</style>
